import React from "react";
import Context from "./context.js";
import axios from "axios";
import "@kenshooui/react-multi-select/dist/style.css";
import { Spinner } from 'evergreen-ui';
import host from "./host";
import Cookies from "universal-cookie";
import { Button, Badge } from 'react-bootstrap';
import * as moment from "moment";
import Logo from './img/logo.jpg';


const cookies = new Cookies();


class Orders extends React.Component {
    constructor() {
        super();
        this.state = {
            orders: [],
            count: '',
            spinPage: true,
            name: '',
            phone: ""
        };

    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `users/printed/orders/order/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                console.log(res.data.data);
                let data = [];

                this.setState({
                    orders: res.data.data.orders,
                    count: res.data.data.count,
                    spinPage: false
                });

            })
            .catch(error => {
                console.log(error.response.data)
            })


        axios.get(host + `dashbord/setting/all`, { headers: header })
            .then(res => {
           

                let setting = res.data.setting
                let name = setting.find((i) => i.name === "توصيل").value
                let phone = setting.find((i) => i.name === "انفراد").value
                this.setState({
                    name: name,
                    phone
                });

            })
            .catch(error => {
                console.log(error.response.data)
            })

    }
    print() {


        var printContents = document.getElementById('ifmcontentstoprint').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    }

    Printing() {
        var Order = this.state.orders;
        var arr = [];

        for (let i = 0; i < Order.length; i++) {
            let items = Order[i].notes2.split("/");
        

            arr.push(
                <div key={i} style={{ height: 728, padding: '0p 10px' }}>
                    <div id='hederPrint'>

                        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', paddingTop: 20 }} >

                            <h6> تاريخ التسليم : {moment(new Date()).format('DD/MM/YYYY')} </h6>

                        </div>
                        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', paddingTop: 20 }} >
                        <h4> رقم الوصل </h4>

                            <h2> {Order[i].id} </h2>
                        </div>
                        <img id='logoPrint' style={{ width: 140 }} src={Logo} alt='logo' />
                    </div>

                    <div id='hederPrint'>
                        <div id='ConTENTListN'>
                            <div id='ConTENTList3'><b>{this.state.name}</b></div>
                        </div>
                    </div>
                    <div id='linePrint' />

                    <div id='hederPrint' style={{ marginTop: 10 }}>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> اسم المرسل</div>
                            <div id='ConTENTList2'>{Order[i].user.name}</div>
                        </div>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> اسم المستلم</div>
                            <div id='ConTENTList2'>{Order[i].name}</div>
                        </div>
                    </div>
                    <div id='hederPrint'>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> هاتف المرسل</div>
                            <div id='ConTENTList2'>{Order[i].user.phone}</div>
                        </div>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'>هاتف المستلم</div>
                            <div id='ConTENTList2'>{Order[i].phone}</div>
                        </div>
                    </div>
                    <div id='hederPrint'>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'> المبلغ الكلي</div>
                            <div id='ConTENTList2'>{Order[i].price}</div>
                        </div>
                        <div id='ConTENTList'>
                            <div id='ConTENTList1'>العنوان</div>
                            <div id='ConTENTList2'>{Order[i].government} / {Order[i].city}</div>
                        </div>
                    </div>
                    <div id='hederPrint'>
                        <div id='ConTENTListN'>
                            <div id='ConTENTList3'>المادة </div>
                            <div id='ConTENTList4' >
                                <div style={{width:"100%", display: "flex", justifyContent: 'center', alignItems: 'center',textAlign:'center' }} >
                                  <div>
                                  {items.map((item, i) => <Badge bg="light" style={{ fontSize: "12px", background: "whate", color: "black" }} text="dark">
                                        {item}
                                    </Badge>)}
                                  </div>
                        

                                </div></div>
                        </div>
                    </div>

                    <div id='hederPrint'>
                        <div id='ConTENTListN'>
                            <div id='ConTENTList3'>ملاحظات</div>
                            <div id='ConTENTList5'>{Order[i].notes} </div>
                        </div>

                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: 'column', textAlign: 'center' }}>
                        <span bg="light" style={{ fontSize: "12px", background: "whate", color: "#999" }} text="dark">
                            انفراد للحلول البرمجية
                        </span>
                        <span bg="light" style={{ fontSize: "12px", background: "whate", color: "#999" }} text="dark">
                            {this.state.phone}
                        </span>

                    </div>





                </div>

            )

        }

        return arr
    }

    render() {

        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        if (!this.state.spinPage) {
                            return (
                                <div style={{ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                    <div id='COOON'>
                                        <h4 style={{ color: '#6896dc' }}> الطلبات </h4>
                                        <div style={{ display: 'flex', margin: 20 }}>


                                            <h4> {this.state.count} </h4>
                                            <h4> : </h4>
                                            <h4> عدد الطلبات </h4>
                                        </div>
                                        <Button variant="primary" style={{ width: 100, margin: 10 }}
                                            onClick={() => {

                                                setTimeout(() => {
                                                    this.print()

                                                }, 200);
                                            }}
                                        >
                                            طباعه
                                        </Button>
                                        <Button variant="success" style={{ width: 100, margin: 10 }} onClick={() => {
                                            window.location.replace("https://hayder.system.enfirad.com/printlist")
                                        }}>
                                            رجوع
                                        </Button>
                                    </div>
                                    <div id='ifmcontentstoprint' style={{ direction: 'rtl' }}>
                                        {this.Printing()}
                                    </div>
                                </div>

                            )
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }

                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default Orders;